import React from 'react'

export default function AdminPage() {
    return (
        <div className="admin-page">
           <div className="admin-page-content text-center">
               <h3 className="text-white">Hi Admin !!!</h3>
               <h6 className="text-white">Chúc bạn một ngày tốt lành ^_^</h6>
           </div>
        </div>
    )
}
