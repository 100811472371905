export const SET_FILM = 'SET_FILM';
export const SET_FILM_DETAIL = 'SET_FILM_DETAIL';
export const SET_CHI_TIET_PHONG_VE = 'SET_CHI_TIET_PHONG_VE';
export const DANG_KY = 'DANG_KY';
export const DANG_NHAP = 'DANG_NHAP';
export const XOA_DANH_SACH_GHE_DANG_DAT = 'XOA_DANH_SACH_GHE_DANG_DAT';
export const DAT_GHE = 'DAT_GHE';
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const SET_CHI_TIET_PHIM_THEO_NGAY = 'SET_CHI_TIET_PHIM_THEO_NGAY';
export const THONG_TIN_TAI_KHOAN = 'THONG_TIN_TAI_KHOAN';
export const TIM_KIEM_PHIM = 'TIM_KIEM_PHIM';
export const SET_CHI_TIET_CUM_RAP = 'SET_CHI_TIET_CUM_RAP';
export const SET_USER = 'SET_USER';
export const TIM_KIEM_USER = 'TIM_KIEM_USER';
